<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.missionPlanning.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.missionPlanning.mainMessage" | transloco }}
        </p>
        <div class="main-image mobile">
            <img
                ngSrc="/assets/images/subpages/mission-planning-laptop.webp"
                [alt]="'dtmMainPageLib.subpage.missionPlanning.bannerAltText' | transloco"
                height="851"
                width="1520"
            />
        </div>
        <div class="redirect-button-container desktop">
            <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
                "dtmMainPageLib.subpage.missionPlanning.redirectLabel" | transloco
            }}</a>
        </div>
    </div>
</div>
<div class="redirect-button-container mobile">
    <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
        "dtmMainPageLib.subpage.missionPlanning.redirectLabel" | transloco
    }}</a>
</div>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/mission-planning-laptop.webp"
        [alt]="'dtmMainPageLib.subpage.missionPlanning.bannerAltText' | transloco"
        dtmUiPageZoomImage
        height="851"
        width="1520"
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/check.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.missionPlanning.profits.supportLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.missionPlanning.profits.supportMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/map.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.missionPlanning.profits.systemVerificationLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.missionPlanning.profits.systemVerificationMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/uav.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.missionPlanning.profits.analysisLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.missionPlanning.profits.analysisMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container information-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/mission-planning-information.webp"
                        [alt]="'dtmMainPageLib.subpage.missionPlanning.informationImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.missionPlanning.informationTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.missionPlanning.informationMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.missionPlanning.permissionsTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.missionPlanning.permissionsMessage" | transloco }}</p>
                <ul class="message-list">
                    <li>{{ "dtmMainPageLib.subpage.missionPlanning.permissionsMessageListItemOne" | transloco }}</li>
                    <li>{{ "dtmMainPageLib.subpage.missionPlanning.permissionsMessageListItemTwo" | transloco }}</li>
                </ul>
            </div>
            <div class="image-container permission-image">
                <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/mission-planning-permission.webp"
                    [alt]="'dtmMainPageLib.subpage.missionPlanning.permissionsImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container safety-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_2.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/mission-planning-safety.webp"
                        [alt]="'dtmMainPageLib.subpage.missionPlanning.safetyImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.missionPlanning.safetyTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.missionPlanning.safetyMessageOne" | transloco }}</p>
                    <p class="message">{{ "dtmMainPageLib.subpage.missionPlanning.safetyMessageTwo" | transloco }}</p>
                    <ul class="message-list">
                        <li>{{ "dtmMainPageLib.subpage.missionPlanning.safetyMessageListItemOne" | transloco }}</li>
                        <li>{{ "dtmMainPageLib.subpage.missionPlanning.safetyMessageListItemTwo" | transloco }}</li>
                        <li>{{ "dtmMainPageLib.subpage.missionPlanning.safetyMessageListItemThree" | transloco }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
