<div class="grid header">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.roadmap.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.roadmap.mainMessage" | transloco }}
        </p>
    </div>
</div>

<div class="sections-container">
    <div class="grid roadmap">
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.history" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.bvlos" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.registrationFromApp" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">3Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.weather" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.droneTower.steps.additionalMissionInformation" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.steps.deployment" | transloco }}
                <div class="estimation">3Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtmGeozones.steps.development" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.description" | transloco }}
            </p>
            <h5>
                {{ "dtmMainPageLib.subpage.roadmap.plannedDevelopment" | transloco }}
            </h5>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.steps.bspTools" | transloco }}
                <div class="estimation">2Q 2025</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.pansaUtm.steps.coordinationTools" | transloco }}
                <div class="estimation">4Q 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.membersInviting" | transloco }}
                <div class="status">
                    <span class="chips chips-soon">
                        <dtm-ui-icon name="postpone"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.soon" | transloco }}
                    </span>
                    <div class="estimation">3Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.dedicatedTrainings" | transloco }}
                <div class="estimation">3Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eLearning.steps.processAutomatization" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.planProceedingAutomatization" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">3Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyMissionPlan.steps.nmpt" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.distanceEditor" | transloco }}
                <div class="estimation">3Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.optimizationStaticRoutes" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dtmAutonomyThreeAreas.steps.phase2" | transloco }}
                <div class="estimation">2Q 2025</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.eSora.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.eSora.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.inopCreate" | transloco }}
                <div class="estimation">3Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.eSora.steps.automaticParameterValidator" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.coTuLata.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">1Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafety.steps.publicInformationAutomatization" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
        <div class="app-section">
            <h4>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.title" | transloco }}
            </h4>
            <p>
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.description" | transloco }}
            </p>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.deployment" | transloco }}
                <div class="status">
                    <span class="chips chips-deployed">
                        <dtm-ui-icon name="checkbox-circle"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.subpage.roadmap.status.deployed" | transloco }}
                    </span>
                    <div class="estimation">2Q 2024</div>
                </div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.lawBasedUpdates" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
            <div class="step">
                {{ "dtmMainPageLib.subpage.roadmap.dynamicSafetySearchHelp.steps.shFlight" | transloco }}
                <div class="estimation">4Q 2024</div>
            </div>
        </div>
    </div>
</div>
