<ng-container *ngrxLet="locale$ as locale">
    <div class="grid">
        <div class="main-section">
            <h1>
                {{ "dtmMainPageLib.subpage.droneTower.header" | transloco }}
            </h1>
            <p class="message">
                {{ "dtmMainPageLib.subpage.droneTower.mainMessage" | transloco }}
            </p>
            <div class="main-image mobile">
                <img
                    ngSrc="/assets/images/subpages/drone-tower-device.webp"
                    [alt]="'dtmMainPageLib.subpage.droneTower.bannerAltText' | transloco"
                    width="471"
                    height="943"
                    priority
                />

                <div class="redirect-buttons-container">
                    <ng-container *ngTemplateOutlet="redirectButtonsTemplate"> </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="redirect-buttons-container desktop">
        <ng-container *ngTemplateOutlet="redirectButtonsTemplate"> </ng-container>
    </div>
    <ng-template #redirectButtonsTemplate>
        <a [href]="DRONE_TOWER_APP_PLAY_STORE_URL" target="_blank" rel="noopener noreferrer" [ngSwitch]="locale">
            <img
                *ngSwitchCase="'en'"
                [ngSrc]="'/assets/images/google-play-badge-en.png'"
                width="320"
                height="95"
                [alt]="'dtmMainPageLib.subpage.droneTower.googlePlayImageAltText' | transloco"
            />
            <img
                *ngSwitchDefault
                [ngSrc]="'/assets/images/google-play-badge-pl.png'"
                width="320"
                height="95"
                [alt]="'dtmMainPageLib.subpage.droneTower.googlePlayImageAltText' | transloco"
            />
        </a>
        <a [href]="DRONE_TOWER_APP_APPLE_STORE_URL" target="_blank" rel="noopener noreferrer" [ngSwitch]="locale">
            <img
                *ngSwitchCase="'en'"
                [ngSrc]="'/assets/images/app-store-badge-en.png'"
                width="320"
                height="95"
                [alt]="'dtmMainPageLib.subpage.droneTower.appStoreImageAltText' | transloco"
            />
            <img
                *ngSwitchDefault
                [ngSrc]="'/assets/images/app-store-badge-pl.png'"
                width="320"
                height="95"
                [alt]="'dtmMainPageLib.subpage.droneTower.appStoreImageAltText' | transloco"
            />
        </a>
    </ng-template>
</ng-container>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/drone-tower-device.webp"
        [alt]="'dtmMainPageLib.subpage.droneTower.bannerAltText' | transloco"
        dtmUiPageZoomImage
        width="471"
        height="943"
        priority
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.droneTower.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/check-in.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.droneTower.profits.checkInLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.droneTower.profits.checkInMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/open-mail.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.droneTower.profits.communicationLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.droneTower.profits.communicationMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/map.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.droneTower.profits.presentationLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.droneTower.profits.presentationMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/tools.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.droneTower.profits.developmentLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.droneTower.profits.developmentMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container instant-check-in-image">
                    <img class="background-blob" alt="" src="/assets/images/subpages/blob_1.svg" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/drone-tower-instant-check-in.webp"
                        [alt]="'dtmMainPageLib.subpage.droneTower.instantCheckInImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.droneTower.instantCheckInTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.droneTower.instantCheckInMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.droneTower.weatherInfoTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.droneTower.weatherInfoMessage" | transloco }}</p>
            </div>
            <div
                class="image-container weather-info-image"
                role="img"
                [attr.aria-label]="'dtmMainPageLib.subpage.droneTower.weatherAndLocationInfoImageAltText' | transloco"
            >
                <img class="background-blob" alt="" src="/assets/images/subpages/blob_2.svg" />
                <img
                    class="section-image image-with-box-shadow weather"
                    src="/assets/images/subpages/drone-tower-weather.webp"
                    [alt]="'dtmMainPageLib.subpage.droneTower.weatherInfoImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
                <img
                    class="section-image image-with-box-shadow location"
                    src="/assets/images/subpages/drone-tower-location.webp"
                    [alt]="'dtmMainPageLib.subpage.droneTower.locationInfoImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container updates-image">
                    <img class="background-blob" alt="" src="/assets/images/subpages/blob_3.svg" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/drone-tower-updates.webp"
                        [alt]="'dtmMainPageLib.subpage.droneTower.updatesImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.droneTower.updatesTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.droneTower.updatesMessage" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
