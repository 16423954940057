import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { DRONE_TOWER_APP_APPLE_STORE_URL, DRONE_TOWER_APP_PLAY_STORE_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-drone-tower-subpage",
    templateUrl: "./drone-tower-subpage.component.html",
    styleUrls: ["../shared.scss", "./drone-tower-subpage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DroneTowerSubpageComponent {
    protected readonly DRONE_TOWER_APP_PLAY_STORE_URL = DRONE_TOWER_APP_PLAY_STORE_URL;
    protected readonly DRONE_TOWER_APP_APPLE_STORE_URL = DRONE_TOWER_APP_APPLE_STORE_URL;
    protected readonly locale$ = this.store.select(UIState.activeLanguage);

    constructor(private readonly store: Store) {}
}
