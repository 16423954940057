export namespace PilotOperatorSearchActions {
    export class SearchPilotOrOperator {
        public static readonly type = "[PilotOperatorSearch] Search pilot or operator";

        constructor(public searchedNumber: string) {}
    }

    export class ClearPilotOperatorSearch {
        public static readonly type = "[PilotOperatorSearch] Clear pilot or operator";
    }
}
