import { UIActions } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";

export const LOCAL_STORAGE_LANGUAGE_KEY = "languageTag";

export function initialAppSetup(localStore: Storage, store: Store, translocoService: TranslocoService) {
    return () => {
        const defaultLanguage =
            typeof navigator !== "undefined"
                ? navigator.language.startsWith("en")
                    ? "en"
                    : navigator.language
                : LANGUAGE_CONFIGURATION.defaultLanguage;

        const language = localStore.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? defaultLanguage;

        const selectedLanguage =
            LANGUAGE_CONFIGURATION.availableLanguages.find((availableLanguage) => availableLanguage === language) ??
            LANGUAGE_CONFIGURATION.defaultLanguage;

        localStore.setItem(LOCAL_STORAGE_LANGUAGE_KEY, selectedLanguage);
        store.dispatch(new UIActions.SetActiveLanguage(selectedLanguage));

        return lastValueFrom(translocoService.load(selectedLanguage));
    };
}
