import { MainPageEnvironment } from "./environment.model";

const apiUrl = "https://bff-webui.dev.pansa.io/api";

export const environment: MainPageEnvironment = {
    production: false,
    name: "dev",
    bearerExcludedUrls: [],
    versionDataEndpoints: {
        getVersionDetails: `${apiUrl}/system/service-info`,
    },
    termsOfUseUrl: `${apiUrl}/terms-of-service/uav-app/current`,
    accessibilityStatementUrl: `${apiUrl}/accessibility-statement`,
    systemStateEndpoints: {
        getSystemState: `${apiUrl}/system/health`,
    },
    pilotOperatorSearchEndpoints: {
        getPilotOrOperator: `${apiUrl}/uav-app/public-profile/{{searchedNumber}}`,
    },
};
