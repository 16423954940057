import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { E_IDENTIFICATION_APP_PLAY_STORE_URL, E_IDENTIFICATION_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-e-identification-subpage",
    templateUrl: "./e-identification-subpage.component.html",
    styleUrls: ["../shared.scss", "./e-identification-subpage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EIdentificationSubpageComponent {
    private readonly store = inject(Store);

    protected readonly E_IDENTIFICATION_URL = E_IDENTIFICATION_URL;
    protected readonly E_IDENTIFICATION_APP_PLAY_STORE_URL = E_IDENTIFICATION_APP_PLAY_STORE_URL;
    protected readonly locale$ = this.store.select(UIState.activeLanguage);
}
