import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "dtm-main-page-lib-services-section",
    templateUrl: "./services-section.component.html",
    styleUrls: ["./services-section.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesSectionComponent {
    protected readonly E_LEARNING_URL = "e-learning";
    protected readonly E_SORA_URL = "e-sora";
    protected readonly E_IDENTIFICATION_URL = "e-identification";
    protected readonly DRONE_TOWER_URL = "drone-tower";
    protected readonly CAA_URL = "caa";
    protected readonly E_REGISTRATION_URL = "e-registration";
    protected readonly MISSION_PLANNING_URL = "mission-planning";
    protected readonly THREE_AREAS_URL = "three-areas";
    protected readonly SEARCH_AND_HELP_URL = "sah";
    protected readonly DSS_URL = "dss";
}
