<section class="services-section grid-container">
    <div class="grid">
        <h2>{{ "dtmMainPageLib.servicesSection.title" | transloco }}</h2>

        <h3>{{ "dtmMainPageLib.servicesSection.uavServicesSubtitle" | transloco }}</h3>
        <ul class="services-list">
            <li>
                <img src="/assets/images/laptop.svg" alt="" />
                <div class="item-content">
                    <h4>
                        {{ "dtmMainPageLib.servicesSection.eRegistration.title" | transloco }}<br />
                        {{ "dtmMainPageLib.servicesSection.eRegistration.subtitle" | transloco }}
                    </h4>

                    <p>{{ "dtmMainPageLib.servicesSection.eRegistration.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="E_REGISTRATION_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/laptop-user.svg" alt="" />
                <div class="item-content">
                    <h4>
                        {{ "dtmMainPageLib.servicesSection.caa.title" | transloco }}<br />{{
                            "dtmMainPageLib.servicesSection.caa.subtitle" | transloco
                        }}
                    </h4>
                    <p>{{ "dtmMainPageLib.servicesSection.caa.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="CAA_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/elearning.svg" alt="" />
                <div class="item-content">
                    <h4>{{ "dtmMainPageLib.servicesSection.eLearning.title" | transloco }}</h4>
                    <p>{{ "dtmMainPageLib.servicesSection.eLearning.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="E_LEARNING_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
        </ul>

        <h3>{{ "dtmMainPageLib.servicesSection.missionPlanningServicesSubtitle" | transloco }}</h3>
        <ul class="services-list">
            <li>
                <img src="/assets/images/map.svg" alt="" />
                <div class="item-content">
                    <h4>
                        {{ "dtmMainPageLib.servicesSection.missionPlanning.title" | transloco }}<br />{{
                            "dtmMainPageLib.servicesSection.missionPlanning.subtitle" | transloco
                        }}
                    </h4>
                    <p>{{ "dtmMainPageLib.servicesSection.missionPlanning.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="MISSION_PLANNING_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/graph.svg" alt="" />
                <div class="item-content">
                    <h4>
                        {{ "dtmMainPageLib.servicesSection.threeAreas.title" | transloco }}<br />{{
                            "dtmMainPageLib.servicesSection.threeAreas.subtitle" | transloco
                        }}
                    </h4>
                    <p>{{ "dtmMainPageLib.servicesSection.threeAreas.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="THREE_AREAS_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/exam.svg" alt="" />
                <div class="item-content">
                    <h4>{{ "dtmMainPageLib.servicesSection.eSora.title" | transloco }}</h4>
                    <p>{{ "dtmMainPageLib.servicesSection.eSora.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="E_SORA_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
        </ul>

        <h3>{{ "dtmMainPageLib.servicesSection.publicSafetySupportServicesSubtitle" | transloco }}</h3>
        <ul class="services-list">
            <li>
                <img src="/assets/images/alert.svg" alt="" />
                <div class="item-content">
                    <h4>{{ "dtmMainPageLib.servicesSection.dss.title" | transloco }}</h4>
                    <p>{{ "dtmMainPageLib.servicesSection.dss.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="DSS_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/life-belt.svg" alt="" />
                <div class="item-content">
                    <h4>
                        {{ "dtmMainPageLib.servicesSection.sah.title" | transloco }}
                    </h4>
                    <p>{{ "dtmMainPageLib.servicesSection.sah.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="SEARCH_AND_HELP_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
            <li>
                <img src="/assets/images/binoculars.svg" alt="" />
                <div class="item-content">
                    <h4>{{ "dtmMainPageLib.servicesSection.eIdentification.title" | transloco }}</h4>
                    <p>{{ "dtmMainPageLib.servicesSection.eIdentification.text" | transloco }}</p>
                    <a class="button button-secondary" [href]="E_IDENTIFICATION_URL">
                        {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                        <span class="iconfont icon-arrow-go"></span>
                    </a>
                </div>
            </li>
        </ul>
        <div class="other-services-section">
            <h3>{{ "dtmMainPageLib.servicesSection.otherServicesSubtitle" | transloco }}</h3>
            <ul class="services-list">
                <li>
                    <img src="/assets/images/uav-2.svg" alt="" />
                    <div class="item-content">
                        <h4>{{ "dtmMainPageLib.servicesSection.droneTower.title" | transloco }}</h4>
                        <p>{{ "dtmMainPageLib.servicesSection.droneTower.text" | transloco }}</p>
                        <a class="button button-secondary" [href]="DRONE_TOWER_URL">
                            {{ "dtmMainPageLib.servicesSection.linkLabel" | transloco }}
                            <span class="iconfont icon-arrow-go"></span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>
