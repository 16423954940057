import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UAV_PANSA_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-mission-planning-subpage",
    templateUrl: "./mission-planning-subpage.component.html",
    styleUrls: ["../shared.scss", "./mission-planning-subpage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionPlanningSubpageComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
}
