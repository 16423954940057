<div class="grid main-section">
    <h2>{{ "dtmMainPageLib.faq.header" | transloco }}</h2>
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
        <p class="question" headerSlot>{{ "dtmMainPageLib.faq.question1" | transloco }}</p>
        <p class="answer">{{ "dtmMainPageLib.faq.answer1" | transloco }}</p>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
        <p class="question" headerSlot>{{ "dtmMainPageLib.faq.question2" | transloco }}</p>
        <p class="answer">{{ "dtmMainPageLib.faq.answer2" | transloco }}</p>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
        <p class="question" headerSlot>{{ "dtmMainPageLib.faq.question3" | transloco }}</p>
        <p class="answer">{{ "dtmMainPageLib.faq.answer3" | transloco }}</p>
    </dtm-ui-expandable-panel>
</div>
