<ng-container *ngrxLet="{ isProcessing: isProcessing$, pilotOperatorSearchResult: pilotOperatorSearchResult$ } as vm">
    <div class="search">
        <h1>{{ "dtmMainPageLib.pilotOperatorSearch.header" | transloco }}</h1>
        <p>{{ "dtmMainPageLib.pilotOperatorSearch.description" | transloco }}</p>

        <div class="search-field">
            <dtm-ui-input-field [class.field-with-error]="searchControl.errors?.notFound">
                <input
                    matInput
                    type="text"
                    [formControl]="searchControl"
                    [placeholder]="'dtmMainPageLib.pilotOperatorSearch.searchPlaceholder' | transloco"
                    [attr.aria-label]="'dtmMainPageLib.pilotOperatorSearch.searchPlaceholder' | transloco"
                />
                <dtm-ui-icon name="search" class="field-suffix"></dtm-ui-icon>
            </dtm-ui-input-field>
            <div class="field-error">
                <ng-container *ngIf="searchControl.errors?.notFound">
                    <span class="icon-container"><dtm-ui-icon name="error-warning-fill"></dtm-ui-icon></span>
                    <p class="error-text">{{ "dtmMainPageLib.pilotOperatorSearch.notFoundError" | transloco }}</p>
                </ng-container>
            </div>
        </div>
    </div>

    <dtm-ui-loader-container [isShown]="vm.isProcessing" class="search-result">
        <ng-container
            *ngIf="vm.pilotOperatorSearchResult?.operator as operator"
            [ngTemplateOutlet]="profileDetailsTemplate"
            [ngTemplateOutletContext]="{ profile: operator, profileType: PROFILE_TYPE.Operator }"
        ></ng-container>
        <ng-container
            *ngIf="vm.pilotOperatorSearchResult?.pilot as pilot"
            [ngTemplateOutlet]="profileDetailsTemplate"
            [ngTemplateOutletContext]="{ profile: pilot, profileType: PROFILE_TYPE.Pilot }"
        ></ng-container>
    </dtm-ui-loader-container>
</ng-container>

<ng-template #profileDetailsTemplate let-profile="profile" let-profileType="profileType">
    <div class="profile">
        <h2 class="searched-value-header">{{ "dtmMainPageLib.pilotOperatorSearch.profileDetailsHeader" | transloco : { profileType } }}</h2>
        <div class="legacy-info" *ngIf="profile.isLegacyProfile" #legacyInfoContainer>
            <dtm-ui-icon name="information-fill" class="info-icon"></dtm-ui-icon>
            <p class="info-text">
                {{ "dtmMainPageLib.pilotOperatorSearch.legacyProfileInfoText" | transloco }}
            </p>
            <button
                type="button"
                class="button-icon close-button"
                [attr.aria-label]="'dtmMainPageLib.pilotOperatorSearch.closeLegacyInfoButtonAriaLabel' | transloco"
                (click)="legacyInfoContainer.classList.add('closed')"
            >
                <dtm-ui-icon name="close"></dtm-ui-icon>
            </button>
            <div class="button-container">
                <a [href]="REGISTER_PANSA_URL" class="button-secondary">
                    {{ "dtmMainPageLib.pilotOperatorSearch.goToRegistrationLinkLabel" | transloco }}</a
                >
            </div>
        </div>
        <div class="details" [class.two-columns]="profile.otherInformation?.length || profile.certificates?.length">
            <div>
                <div class="label-value-container">
                    <p class="label">{{ "dtmMainPageLib.pilotOperatorSearch.numberLabel" | transloco : { profileType } }}</p>
                    <p class="value">{{ profile.number }}</p>
                </div>
                <div class="label-value-container">
                    <p class="label">{{ "dtmMainPageLib.pilotOperatorSearch.statusLabel" | transloco }}</p>
                    <dtm-ui-operator-status-badge class="value" [status]="profile.status"></dtm-ui-operator-status-badge>
                </div>
                <div class="label-value-container list" *ngIf="profile.qualifications?.length">
                    <p class="label">{{ "dtmMainPageLib.pilotOperatorSearch.qualificationsLabel" | transloco : { profileType } }}</p>
                    <div class="qualifications-list">
                        <dtm-ui-qualification-badge *ngFor="let qualification of profile.qualifications" [qualification]="qualification">
                        </dtm-ui-qualification-badge>
                    </div>
                </div>
            </div>
            <div>
                <div class="label-value-container list" *ngIf="profile.certificates?.length">
                    <p class="label">{{ "dtmMainPageLib.pilotOperatorSearch.certificatesLabel" | transloco }}</p>
                    <ul class="certificates-container">
                        <li class="certificate" *ngFor="let certificate of profile.certificates">
                            <dtm-ui-icon name="contract"></dtm-ui-icon>
                            <div class="certificate-details">
                                <div class="certificate-name">{{ certificate.name }}</div>
                                <div class="certificate-expiration-date">
                                    {{
                                        "dtmMainPageLib.pilotOperatorSearch.expirationDateValue"
                                            | transloco : { expirationDate: certificate.validUntil | localizeDate }
                                    }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="label-value-container list" *ngIf="profile.otherInformation?.length">
                    <p class="label">{{ "dtmMainPageLib.pilotOperatorSearch.otherInformationLabel" | transloco }}</p>
                    <ul class="other-information-container">
                        <li class="info-with-icon" *ngFor="let otherInformation of profile.otherInformation">
                            <dtm-ui-icon name="file-info"></dtm-ui-icon>
                            <p>{{ otherInformation.text }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
