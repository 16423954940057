import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
    PilotOrOperatorSearchResult,
    PilotOrOperatorSearchResultError,
    PilotOrOperatorSearchResultErrorType,
} from "./pilot-operaor-search.models";
import { PILOT_OPERATOR_SEARCH_API_TOKENS, PilotOperatorSearchEndpoints } from "./pilot-operator-search-api.tokens";
import {
    GetPilotOrOperatorSearchResultResponseBody,
    convertGetPilotOrOperatorSearchResultResponseBodyToPilotOrOperatorSearchResult,
} from "./pilot-operator-search.converter";

@Injectable({
    providedIn: "root",
})
export class PilotOperatorSearchApiService {
    constructor(
        @Inject(PILOT_OPERATOR_SEARCH_API_TOKENS) private readonly endpoints: PilotOperatorSearchEndpoints,
        private readonly http: HttpClient
    ) {}

    public searchPilotOrOperator(searchedNumber: string): Observable<PilotOrOperatorSearchResult> {
        return this.http
            .get<GetPilotOrOperatorSearchResultResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPilotOrOperator, { searchedNumber })
            )
            .pipe(
                map((response) => convertGetPilotOrOperatorSearchResultResponseBodyToPilotOrOperatorSearchResult(response)),
                catchError((error) => throwError(() => this.getPilotOperatorSearchError(error)))
            );
    }

    private getPilotOperatorSearchError(error: HttpErrorResponse): PilotOrOperatorSearchResultError {
        if (error.status === HttpStatusCode.NotFound) {
            return { type: PilotOrOperatorSearchResultErrorType.NotFound };
        }

        return { type: PilotOrOperatorSearchResultErrorType.Unknown };
    }
}
