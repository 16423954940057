import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UAV_PANSA_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-e-registration",
    templateUrl: "./e-registration-subpage.component.html",
    styleUrls: ["../shared.scss", "./e-registration-subpage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ERegistrationSubpageComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
}
