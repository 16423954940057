import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

function bootstrap() {
    platformBrowserDynamic().bootstrapModule(AppModule);
}

if (environment.production) {
    enableProdMode();
}

if (document.readyState !== "loading") {
    bootstrap();
} else {
    document.addEventListener("DOMContentLoaded", bootstrap);
}
