<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.dss.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.dss.mainMessage" | transloco }}
        </p>
    </div>
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.dss.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/life-belt.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.dss.profits.firstAidLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.dss.profits.firstAidMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/alert.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.dss.profits.publicOrderLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.dss.profits.publicOrderMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/shield.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.dss.profits.safetyLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.dss.profits.safetyMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.dss.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container digitization-image">
                    <img class="background-blob" alt="" src="/assets/images/subpages/blob_3.svg" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/dss-digitization.webp"
                        [alt]="'dtmMainPageLib.subpage.dss.digitizationImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.dss.digitizationTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.dss.digitizationMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.dss.presentationTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.dss.presentationMessage" | transloco }}</p>
            </div>
            <div class="image-container presentation-image">
                <img class="background-blob" src="/assets/images/subpages/blob_2.svg" alt="" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/dss-presentation.webp"
                    [alt]="'dtmMainPageLib.subpage.dss.presentationImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container communication-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/dss-communication.webp"
                        [alt]="'dtmMainPageLib.subpage.dss.communicationImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.dss.communicationTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.dss.communicationMessage" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
