import { ChangeDetectionStrategy, Component } from "@angular/core";
import { REGISTER_PANSA_URL, UAV_PANSA_URL } from "../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-welcome-section",
    templateUrl: "./welcome-section.component.html",
    styleUrls: ["./welcome-section.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeSectionComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
    protected readonly REGISTER_PANSA_URL = REGISTER_PANSA_URL;
}
