<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.eSora.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.eSora.mainMessage" | transloco }}
        </p>
        <div class="main-image mobile">
            <img
                ngSrc="/assets/images/subpages/e-sora-laptop.webp"
                [alt]="'dtmMainPageLib.subpage.eSora.bannerAltText' | transloco"
                width="1520"
                height="851"
            />
        </div>

        <div class="redirect-button-container desktop">
            <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
                "dtmMainPageLib.subpage.eSora.redirectLabel" | transloco
            }}</a>
        </div>
    </div>
</div>

<div class="redirect-button-container mobile">
    <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
        "dtmMainPageLib.subpage.eSora.redirectLabel" | transloco
    }}</a>
</div>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/e-sora-laptop.webp"
        [alt]="'dtmMainPageLib.subpage.eSora.bannerAltText' | transloco"
        dtmUiPageZoomImage
        width="1520"
        height="851"
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/suitcase.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eSora.profits.businessHelpLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eSora.profits.businessHelpMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/alert.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eSora.profits.reliabilityLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eSora.profits.reliabilityMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/laptop-user.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eSora.profits.supportLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eSora.profits.supportMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/check.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eSora.profits.compatibilityLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eSora.profits.compatibilityMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container user-support-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-sora-user-support.webp"
                        alt=""
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eSora.usersSupportTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eSora.usersSupportMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.eSora.operationAreaGeometryTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.eSora.operationAreaGeometryMessage" | transloco }}</p>
            </div>
            <div class="image-container operation-area-geometry-image">
                <img class="background-blob" src="/assets/images/subpages/blob_2.svg" alt="" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/e-sora-operation-area-geometry.webp"
                    alt=""
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container operation-risk-mitigation-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-sora-operation-risk-mitigation.webp"
                        alt=""
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eSora.operationRiskMitigationTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.eSora.operationRiskMitigationMessage" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
