import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map } from "rxjs";
import { SystemStateResponseBody, transformSystemStateResponseBodyToSystemState } from "./system-state-api.converter";
import { SYSTEM_STATE_API_TOKENS } from "./system-state-api.tokens";

@Injectable({
    providedIn: "root",
})
export class SystemStateApiService {
    private readonly http = inject(HttpClient);
    private readonly apiTokens = inject(SYSTEM_STATE_API_TOKENS);

    public getSystemState() {
        return this.http
            .get<SystemStateResponseBody>(this.apiTokens.getSystemState)
            .pipe(map((response) => transformSystemStateResponseBodyToSystemState(response)));
    }
}
