import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FAQ_SECTION } from "../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-main-page-container",
    templateUrl: "./main-page-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPageContainerComponent {
    protected readonly FAQ_SECTION = FAQ_SECTION;
}
