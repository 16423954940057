<section class="welcome-section grid">
    <div class="content grid-col-6 grid-col-tablet-7">
        <h1>{{ "dtmMainPageLib.welcomeSection.title" | transloco }}</h1>
        <p>{{ "dtmMainPageLib.welcomeSection.subtitle" | transloco }}</p>
        <div class="call-to-action-buttons">
            <a [href]="UAV_PANSA_URL" class="button button-primary login-button">
                {{ "dtmMainPageLib.welcomeSection.loginLinkLabel" | transloco }}
            </a>
            <a [href]="REGISTER_PANSA_URL" class="button button-secondary register-button">
                {{ "dtmMainPageLib.welcomeSection.registerLinkLabel" | transloco }}
            </a>
        </div>
    </div>
    <picture class="img-container grid-col-6 grid-col-tablet-5">
        <source media="(min-width: 768px)" srcset="/assets/images/city.svg" />
        <img [alt]="'dtmMainPageLib.welcomeSection.bannerAltText' | transloco" src="/assets/images/city-mobile.svg" />
    </picture>
</section>
