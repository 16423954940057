import { OperationScenarioCategory, OperatorStatus } from "@dtm-frontend/shared/ui";
import { PilotOrOperatorOtherInformation, PilotOrOperatorSearchResult } from "./pilot-operaor-search.models";

export interface GetPilotOrOperatorSearchResultResponseBody {
    operator?: OperatorSearchResultResponseBody;
    pilot?: PilotSearchResultResponseBody;
}

interface PilotSearchResultResponseBody {
    status: OperatorStatus;
    number: string;
    competencies: PilotOrOperatorQualificationResponseBody[];
    certificates: PilotCertificatesResponseBody[];
    otherInformation: PilotOrOperatorOtherInformation[];
    legacyProfile: boolean;
}

interface PilotOrOperatorQualificationResponseBody {
    name: OperatorStatus;
    category: OperationScenarioCategory;
    expirationDate: string;
}

interface PilotCertificatesResponseBody {
    name: string;
    validUntil: string;
}

interface OperatorSearchResultResponseBody {
    status: OperatorStatus;
    number: string;
    operationalAuthorizations: PilotOrOperatorQualificationResponseBody[];
    otherInformation: PilotOrOperatorOtherInformation[];
    legacyProfile: boolean;
}

export function convertGetPilotOrOperatorSearchResultResponseBodyToPilotOrOperatorSearchResult(
    response: GetPilotOrOperatorSearchResultResponseBody
): PilotOrOperatorSearchResult {
    return {
        operator: response.operator
            ? {
                  status: response.operator.status,
                  number: response.operator.number,
                  qualifications: response.operator.operationalAuthorizations.map((operationalAuthorization) => ({
                      name: operationalAuthorization.name,
                      category: operationalAuthorization.category,
                      expirationDate: new Date(operationalAuthorization.expirationDate),
                  })),
                  otherInformation: response.operator.otherInformation,
                  isLegacyProfile: response.operator.legacyProfile,
              }
            : undefined,
        pilot: response.pilot
            ? {
                  status: response.pilot.status,
                  number: response.pilot.number,
                  qualifications: response.pilot.competencies.map((competencie) => ({
                      name: competencie.name,
                      category: competencie.category,
                      expirationDate: new Date(competencie.expirationDate),
                  })),
                  certificates: response.pilot.certificates.map((certificate) => ({
                      name: certificate.name,
                      validUntil: new Date(certificate.validUntil),
                  })),
                  otherInformation: response.pilot.otherInformation,
                  isLegacyProfile: response.pilot.legacyProfile,
              }
            : undefined,
    };
}
