<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.eRegistration.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.eRegistration.mainMessage" | transloco }}
        </p>
        <div class="main-image mobile">
            <img
                ngSrc="/assets/images/subpages/e-registration-laptop.webp"
                [alt]="'dtmMainPageLib.subpage.eRegistration.bannerAltText' | transloco"
                height="851"
                width="1520"
            />
        </div>
        <div class="redirect-button-container desktop">
            <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
                "dtmMainPageLib.subpage.eRegistration.redirectLabel" | transloco
            }}</a>
        </div>
    </div>
</div>

<div class="redirect-button-container mobile">
    <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
        "dtmMainPageLib.subpage.eRegistration.redirectLabel" | transloco
    }}</a>
</div>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/e-registration-laptop.webp"
        [alt]="'dtmMainPageLib.subpage.eRegistration.bannerAltText' | transloco"
        dtmUiPageZoomImage
        height="851"
        width="1520"
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/laptop.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eRegistration.profits.complexityLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.profits.complexityMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/laptop-user.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eRegistration.profits.speedLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.profits.speedMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/open-mail.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eRegistration.profits.communicationLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.profits.communicationMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/shield.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eRegistration.profits.safetyLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.profits.safetyMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container profile-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-registration-profile.webp"
                        [alt]="'dtmMainPageLib.subpage.eRegistration.pilotsAndOperatorsImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eRegistration.pilotsAndOperatorsTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.pilotsAndOperatorsMessageOne" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eRegistration.pilotsAndOperatorsMessageTwo" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.eRegistration.uavManagementTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.eRegistration.uavManagementMessageOne" | transloco }}</p>
                <p class="message">{{ "dtmMainPageLib.subpage.eRegistration.uavManagementMessageTwo" | transloco }}</p>
            </div>
            <div class="image-container uav-management-image">
                <img class="background-blob" alt="" src="/assets/images/subpages/blob_2.svg" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/e-registration-uav-management.webp"
                    [alt]="'dtmMainPageLib.subpage.eRegistration.uavManagementImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container personnel-management-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-registration-personnel-management.webp"
                        [alt]="'dtmMainPageLib.subpage.eRegistration.personnelManagementImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>

                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eRegistration.personnelManagementTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.eRegistration.personnelManagementMessageOne" | transloco }}</p>
                    <p class="message">{{ "dtmMainPageLib.subpage.eRegistration.personnelManagementMessageTwo" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
