import { A11yModule } from "@angular/cdk/a11y";
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { FaqComponent } from "./components/faq/faq.component";
import { MainPageContainerComponent } from "./components/main-page-container/main-page-container.component";
import { MenuComponent } from "./components/menu/menu.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PilotOperatorSearchComponent } from "./components/pilot-operator-search/pilot-operator-search.component";
import { PromptComponent } from "./components/prompt/prompt.component";
import { ServicesSectionComponent } from "./components/services-section/services-section.component";
import { CaaSubpageComponent } from "./components/subpages/caa-subpage/caa-subpage.component";
import { DroneTowerSubpageComponent } from "./components/subpages/drone-tower-subpage/drone-tower-subpage.component";
import { DssSubpageComponent } from "./components/subpages/dss-subpage/dss-subpage.component";
import { EIdentificationSubpageComponent } from "./components/subpages/e-identification-subpage/e-identification-subpage.component";
import { ELearningSubpageComponent } from "./components/subpages/e-learning-subpage/e-learning-subpage.component";
import { ERegistrationSubpageComponent } from "./components/subpages/e-registration-subpage/e-registration-subpage.component";
import { ESoraSubpageComponent } from "./components/subpages/e-sora-subpage/e-sora-subpage.component";
import { MissionPlanningSubpageComponent } from "./components/subpages/mission-planning-subpage/mission-planning-subpage.component";
import { RoadmapSubpageComponent } from "./components/subpages/roadmap-subpage/roadmap-subpage.component";
import { SahSubpageComponent } from "./components/subpages/sah-subpage/sah-subpage.component";
import { ThreeAreasSubpageComponent } from "./components/subpages/three-areas-subpage/three-areas-subpage.component";
import { SystemStateComponent } from "./components/system-state/system-state.component";
import { WelcomeSectionComponent } from "./components/welcome-section/welcome-section.component";
import { PilotOperatorSearchApiService } from "./services/pilot-operator-search/pilot-operator-search-api.service";
import { PilotOperatorSearchState } from "./services/pilot-operator-search/pilot-operator-search.state";

@NgModule({
    declarations: [
        MainPageContainerComponent,
        WelcomeSectionComponent,
        ServicesSectionComponent,
        CaaSubpageComponent,
        ERegistrationSubpageComponent,
        ELearningSubpageComponent,
        MissionPlanningSubpageComponent,
        ThreeAreasSubpageComponent,
        ESoraSubpageComponent,
        DssSubpageComponent,
        SahSubpageComponent,
        EIdentificationSubpageComponent,
        DroneTowerSubpageComponent,
        RoadmapSubpageComponent,
        MenuComponent,
        PromptComponent,
        FaqComponent,
        SystemStateComponent,
        PageNotFoundComponent,
        PilotOperatorSearchComponent,
    ],
    imports: [
        CommonModule,
        SharedI18nModule,
        IconDirective,
        LetModule,
        RouterLinkActive,
        RouterLink,
        MatSidenavModule,
        SharedUiModule,
        A11yModule,
        PushModule,
        CdkOverlayOrigin,
        NgOptimizedImage,
        MatLegacyInputModule,
        ReactiveFormsModule,
        NgxsModule.forFeature([PilotOperatorSearchState]),
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmMainPageLib",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        WelcomeSectionComponent,
        ServicesSectionComponent,
        CaaSubpageComponent,
        ERegistrationSubpageComponent,
        ELearningSubpageComponent,
        MissionPlanningSubpageComponent,
        ThreeAreasSubpageComponent,
        ESoraSubpageComponent,
        DssSubpageComponent,
        SahSubpageComponent,
        EIdentificationSubpageComponent,
        DroneTowerSubpageComponent,
        MenuComponent,
        PromptComponent,
        FaqComponent,
        PageNotFoundComponent,
    ],
})
export class MainPageLibModule {
    public static forTest(): ModuleWithProviders<MainPageLibModule> {
        return {
            ngModule: MainPageLibModule,
            providers: [
                {
                    provide: PilotOperatorSearchApiService,
                    useValue: null,
                },
            ],
        };
    }
}
