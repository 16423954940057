import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IsActiveMatchOptions } from "@angular/router";
import { LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { FAQ_SECTION, MAIN_PAGE_SERVICES_LIST } from "../../utils/defaults";

interface MenuComponentState {
    isServicesSectionOpen: boolean;
    activeLanguage: LanguageCode | undefined;
}

@Component({
    selector: "dtm-main-page-lib-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MenuComponent {
    @Output() public readonly menuCollapse = new EventEmitter<void>();
    @Output() public readonly activeLanguageSet = new EventEmitter<LanguageCode>();
    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value });
    }

    protected readonly isServicesSectionOpen$ = this.localStore.selectByKey("isServicesSectionOpen");
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage").pipe(RxjsUtils.filterFalsy());
    protected readonly MAIN_PAGE_SERVICES_LIST = MAIN_PAGE_SERVICES_LIST;
    protected readonly FAQ_SECTION = FAQ_SECTION;
    protected readonly linkActiveOptions: IsActiveMatchOptions = {
        matrixParams: "exact",
        queryParams: "exact",
        paths: "exact",
        fragment: "exact",
    };
    protected readonly languages = LANGUAGE_CONFIGURATION.languageDefinitions;

    constructor(private readonly localStore: LocalComponentStore<MenuComponentState>) {
        localStore.setState({ isServicesSectionOpen: false, activeLanguage: undefined });
    }

    protected toggleServicesList() {
        this.localStore.patchState(({ isServicesSectionOpen }) => ({ isServicesSectionOpen: !isServicesSectionOpen }));
    }
}
