<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.eIdentification.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.eIdentification.mainMessage" | transloco }}
        </p>
        <!-- TODO: Will be removed in REJ-2898 -->
        <p class="message register-message">
            {{ "dtmMainPageLib.subpage.eIdentification.registerMessage" | transloco }}
        </p>
        <div class="main-image mobile">
            <div class="redirect-buttons-container mobile">
                <ng-container *ngTemplateOutlet="redirectButtonsTemplate"> </ng-container>
            </div>
            <img
                ngSrc="/assets/images/subpages/e-identification-laptop.webp"
                [alt]="'dtmMainPageLib.subpage.eIdentification.bannerAltText' | transloco"
                height="851"
                width="1522"
            />
        </div>
    </div>
</div>
<div class="redirect-buttons-container desktop">
    <ng-container *ngTemplateOutlet="redirectButtonsTemplate"> </ng-container>
</div>
<ng-template #redirectButtonsTemplate>
    <a
        *ngrxLet="locale$ as locale"
        class="google-play"
        [href]="E_IDENTIFICATION_APP_PLAY_STORE_URL"
        target="_blank"
        rel="noopener noreferrer"
        [ngSwitch]="locale"
    >
        <img *ngSwitchCase="'en'" [ngSrc]="'/assets/images/google-play-badge-en.png'" width="320" height="95" alt="" />
        <img *ngSwitchDefault [ngSrc]="'/assets/images/google-play-badge-pl.png'" width="320" height="95" alt="" />
    </a>
    <a class="button-primary" [href]="E_IDENTIFICATION_URL" target="_blank" rel="noopener noreferrer">
        {{ "dtmMainPageLib.subpage.eIdentification.redirectLabel" | transloco }}
    </a>
</ng-template>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/e-identification-laptop.webp"
        [alt]="'dtmMainPageLib.subpage.eIdentification.bannerAltText' | transloco"
        dtmUiPageZoomImage
        width="1522"
        height="851"
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/shield.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eIdentification.profits.safetyLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eIdentification.profits.safetyMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/alert.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.eIdentification.profits.reportLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eIdentification.profits.reportMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/laptop.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eIdentification.profits.interfaceLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eIdentification.profits.interfaceMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/info.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.eIdentification.profits.trackingProgressLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eIdentification.profits.trackingProgressMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container presentation-image">
                    <img class="background-blob" alt="" src="/assets/images/subpages/blob_1.svg" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-identification-presentation.webp"
                        [alt]="'dtmMainPageLib.subpage.eIdentification.presentationImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eIdentification.presentationTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.eIdentification.presentationMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.eIdentification.reportingTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.eIdentification.reportingMessage" | transloco }}</p>
            </div>
            <div class="image-container reporting-image">
                <img class="background-blob" alt="" src="/assets/images/subpages/blob_2.svg" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/e-identification-reporting.webp"
                    [alt]="'dtmMainPageLib.subpage.eIdentification.reportingImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container monitoring-image">
                    <img class="background-blob" alt="" src="/assets/images/subpages/blob_3.svg" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/e-identification-monitoring.webp"
                        [alt]="'dtmMainPageLib.subpage.eIdentification.monitoringImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.eIdentification.monitoringTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.eIdentification.monitoringMessage" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
