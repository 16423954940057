export const REGISTER_PANSA_URL = "https://register.uav.pansa.pl";
export const UAV_PANSA_URL = "https://uav.pansa.pl";
export const E_LEARNING_URL = "https://elearning.uav.pansa.pl";
export const E_IDENTIFICATION_URL = "http://cotulata.pl";
export const E_IDENTIFICATION_APP_PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=pl.pansa.uavidentification";
export const DRONE_TOWER_APP_PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=pl.pansa.dronetower";
export const DRONE_TOWER_APP_APPLE_STORE_URL = "https://apps.apple.com/app/id6477453743";
export const FAQ_SECTION = "faq";

export const MAIN_PAGE_SERVICES_LIST: { link: string; labelTranslationKey: string; subLabelTranslationKey?: string }[] = [
    {
        link: "e-registration",
        labelTranslationKey: "dtmMainPageLib.menu.eRegistrationMenuItemLabel",
    },
    {
        link: "caa",
        labelTranslationKey: "dtmMainPageLib.menu.caaMenuItemLabel",
    },
    { link: "e-learning", labelTranslationKey: "dtmMainPageLib.menu.eLearningMenuItemLabel" },
    {
        link: "mission-planning",
        labelTranslationKey: "dtmMainPageLib.menu.missionPlanningMenuItemLabel",
    },
    {
        link: "three-areas",
        labelTranslationKey: "dtmMainPageLib.menu.threeAreasMenuItemLabel",
    },
    { link: "e-sora", labelTranslationKey: "dtmMainPageLib.menu.eSoraMenuItemLabel" },
    { link: "dss", labelTranslationKey: "dtmMainPageLib.menu.dssMenuItemLabel" },
    { link: "sah", labelTranslationKey: "dtmMainPageLib.menu.sahMenuItemLabel" },
    { link: "e-identification", labelTranslationKey: "dtmMainPageLib.menu.eIdentificationMenuItemLabel" },
    { link: "drone-tower", labelTranslationKey: "dtmMainPageLib.menu.droneTowerMenuItemLabel" },
];
