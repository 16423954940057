import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DEFAULT_DEBOUNCE_TIME, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { PilotOrOperatorSearchResultErrorType } from "../../services/pilot-operator-search/pilot-operaor-search.models";
import { PilotOperatorSearchActions } from "../../services/pilot-operator-search/pilot-operator-search.actions";
import { PilotOperatorSearchState } from "../../services/pilot-operator-search/pilot-operator-search.state";
import { REGISTER_PANSA_URL } from "../../utils/defaults";

enum PROFILE_TYPE {
    Operator = "operator",
    Pilot = "pilot",
}

@UntilDestroy()
@Component({
    selector: "dtm-main-page-lib-pilot-operator-search",
    templateUrl: "./pilot-operator-search.component.html",
    styleUrls: ["./pilot-operator-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PilotOperatorSearchComponent {
    protected readonly searchControl = new FormControl<string>("", { nonNullable: true });
    protected readonly pilotOperatorSearchResult$ = this.store.select(PilotOperatorSearchState.pilotOrOperator);
    protected readonly pilotOperatorSearchResultError$ = this.store.select(PilotOperatorSearchState.error);
    protected readonly isProcessing$ = this.store.select(PilotOperatorSearchState.isProcessing);
    protected readonly PROFILE_TYPE = PROFILE_TYPE;
    protected readonly REGISTER_PANSA_URL = REGISTER_PANSA_URL;

    constructor(private readonly store: Store, private readonly activatedRoute: ActivatedRoute) {
        this.pilotOperatorSearchResultError$
            .pipe(
                RxjsUtils.filterFalsy(),
                tap((error) => {
                    if (error.type === PilotOrOperatorSearchResultErrorType.NotFound) {
                        this.searchControl.setErrors({ notFound: true });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.searchControl.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (this.searchControl.errors?.notFound) {
                    this.searchControl.errors.notFound = null;
                }

                if (value.length) {
                    this.store.dispatch(new PilotOperatorSearchActions.SearchPilotOrOperator(value));

                    return;
                }
                this.store.dispatch(PilotOperatorSearchActions.ClearPilotOperatorSearch);
            });

        this.setInitialSearchFromQueryParams();
    }

    private setInitialSearchFromQueryParams(): void {
        const pilotOperatorSearchQueryParam = "number";
        const number = this.activatedRoute.snapshot.queryParamMap.get(pilotOperatorSearchQueryParam);

        if (number) {
            this.searchControl.setValue(number);
        }
    }
}
