<button
    type="button"
    class="button-icon close-button"
    (click)="menuCollapse.emit()"
    [attr.aria-label]="'mainPage.menu.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: true }"
>
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>

<ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link" let-fragment="fragment">
    <a
        [routerLink]="link"
        routerLinkActive="active-route"
        [fragment]="fragment"
        (click)="menuCollapse.emit()"
        [routerLinkActiveOptions]="linkActiveOptions"
    >
        <dtm-ui-icon *ngIf="icon" [name]="icon"></dtm-ui-icon>
        <span class="label">{{ label }}</span>
    </a>
</ng-template>

<ul class="menu-list">
    <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMainPageLib.menu.dashboardMenuItemLabel' | transloco,
                link: '/'
            }"
        ></ng-template>
    </li>
</ul>
<ng-container *ngrxLet="isServicesSectionOpen$ as isServicesSectionOpen">
    <button
        type="button"
        class="section-section-expandable"
        (click)="toggleServicesList()"
        [attr.aria-expanded]="isServicesSectionOpen"
        [attr.aria-label]="'mainPage.menu.serviceMenuStateAriaLabel' | transloco : { state: isServicesSectionOpen }"
    >
        <span class="label">{{ "dtmMainPageLib.menu.servicesMenuItemLabel" | transloco }}</span>
        <dtm-ui-icon [name]="isServicesSectionOpen ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
    </button>
    <ul class="menu-list services-list" *ngIf="isServicesSectionOpen">
        <li *ngFor="let servicesMenuItem of MAIN_PAGE_SERVICES_LIST">
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: servicesMenuItem.labelTranslationKey | transloco,
                    link: servicesMenuItem.link
                }"
            ></ng-template>
        </li>
    </ul>
</ng-container>
<ul class="menu-list">
    <!--  TODO: REJ-2688 enable after translations ready -->
    <!-- <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMainPageLib.menu.faqLabel' | transloco,
                link: '/',
                fragment: FAQ_SECTION
            }"
        ></ng-template>
    </li> -->
    <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMainPageLib.menu.pilotOperatorSearchLabel' | transloco,
                link: '/pilot-operator-search'
            }"
        ></ng-template>
    </li>
    <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMainPageLib.menu.roadmapLabel' | transloco,
                link: '/roadmap'
            }"
        ></ng-template>
    </li>
    <li>
        <ng-template
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
                label: 'dtmMainPageLib.menu.systemStateLabel' | transloco,
                link: '/system-state'
            }"
        ></ng-template>
    </li>
    <li>
        <dtm-ui-language-select
            [languages]="languages"
            [selectedLanguage]="activeLanguage$ | ngrxPush"
            (languageChange)="activeLanguageSet.emit($event)"
        >
        </dtm-ui-language-select>
    </li>
</ul>
