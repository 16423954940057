<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.sah.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.sah.mainMessage" | transloco }}
        </p>
    </div>
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.sah.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/check.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.sah.profits.resourcesLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.profits.resourcesMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/virtual-map.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.sah.profits.virtualMapLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.profits.virtualMapMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/exam.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.sah.profits.notesLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.profits.notesMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.sah.howItWorksHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container map-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/sah-map.webp"
                        [alt]="'dtmMainPageLib.subpage.sah.virtualMapImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.sah.virtualMapTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.virtualMapMessageOne" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.virtualMapMessageTwo" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.virtualMapMessageThree" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.virtualMapMessageFour" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.sah.virtualMapMessageFive" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
