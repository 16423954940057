<header class="header" [ngClass]="isPlatformServer ? 'ssr-view' : 'browser-view'" *ngrxLet="{ isMenuClosed: isMenuClosed$ } as vm">
    <div class="smartphone-header">
        <button
            type="button"
            class="button-icon menu-button"
            (click)="toggleSidebar()"
            [attr.aria-label]="'mainPage.menu.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: false }"
            [attr.aria-expanded]="!vm.isMenuClosed"
        >
            <span class="iconfont icon-menu"></span>
        </button>
        <div class="logo" *ngIf="vm.isMenuClosed">
            <a routerLink="/"><img src="assets/images/logo.svg" [alt]="'mainPage.header.logoAltText' | transloco" /></a>
        </div>
    </div>
    <div class="desktop-header grid">
        <a routerLink="/" class="logo"><img src="assets/images/logo.svg" [alt]="'mainPage.header.logoAltText' | transloco" /></a>
        <nav class="links">
            <a routerLink="/">{{ "mainPage.header.homeLinkLabel" | transloco }}</a>
            <button
                type="button"
                class="services-list-menu-button"
                #servicesMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                (click)="toggleServicesMenu(true)"
                [class.services-list-menu-active]="isServicesMenuOpen$ | ngrxPush"
            >
                {{ "mainPage.header.servicesLinkLabel" | transloco }} <span class="iconfont icon-arrow-down"></span>
            </button>
            <!--  TODO: REJ-2688 enable after translations ready -->
            <!-- <a routerLink="/" [fragment]="FAQ_SECTION">{{ "mainPage.header.faqLinkLabel" | transloco }}</a> -->
            <a routerLink="/pilot-operator-search">{{ "mainPage.header.pilotOperatorSearchLabel" | transloco }}</a>
            <a routerLink="/roadmap">{{ "mainPage.header.roadmapLabel" | transloco }}</a>
            <a routerLink="/system-state">{{ "mainPage.header.systemStateLabel" | transloco }}</a>
        </nav>
        <div class="user-options">
            <dtm-ui-language-select
                [languages]="languages"
                [selectedLanguage]="activeLanguage$ | ngrxPush"
                (languageChange)="setActiveLanguage($event)"
            >
                <span class="field-suffix trigger-button ssr-visible"><span class="iconfont icon-arrow-down"></span></span>
            </dtm-ui-language-select>
        </div>
    </div>
</header>

<mat-menu #menu="matMenu" class="services-list" (closed)="toggleServicesMenu(false)">
    <a *ngFor="let menuItem of MAIN_PAGE_SERVICES_LIST" class="button-link service-menu-item" mat-menu-item [routerLink]="menuItem.link">
        {{ menuItem.labelTranslationKey | transloco }}
    </a>
</mat-menu>
