import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NOT_FOUND_ROUTE } from "@dtm-frontend/shared/utils";
import { MainPageContainerComponent } from "./components/main-page-container/main-page-container.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PilotOperatorSearchComponent } from "./components/pilot-operator-search/pilot-operator-search.component";
import { CaaSubpageComponent } from "./components/subpages/caa-subpage/caa-subpage.component";
import { DroneTowerSubpageComponent } from "./components/subpages/drone-tower-subpage/drone-tower-subpage.component";
import { DssSubpageComponent } from "./components/subpages/dss-subpage/dss-subpage.component";
import { EIdentificationSubpageComponent } from "./components/subpages/e-identification-subpage/e-identification-subpage.component";
import { ELearningSubpageComponent } from "./components/subpages/e-learning-subpage/e-learning-subpage.component";
import { ERegistrationSubpageComponent } from "./components/subpages/e-registration-subpage/e-registration-subpage.component";
import { ESoraSubpageComponent } from "./components/subpages/e-sora-subpage/e-sora-subpage.component";
import { MissionPlanningSubpageComponent } from "./components/subpages/mission-planning-subpage/mission-planning-subpage.component";
import { RoadmapSubpageComponent } from "./components/subpages/roadmap-subpage/roadmap-subpage.component";
import { SahSubpageComponent } from "./components/subpages/sah-subpage/sah-subpage.component";
import { ThreeAreasSubpageComponent } from "./components/subpages/three-areas-subpage/three-areas-subpage.component";
import { SystemStateComponent } from "./components/system-state/system-state.component";

const routes: Routes = [
    {
        path: "",
        component: MainPageContainerComponent,
    },
    { path: "caa", component: CaaSubpageComponent },
    { path: "drone-tower", component: DroneTowerSubpageComponent },
    { path: "dss", component: DssSubpageComponent },
    { path: "e-registration", component: ERegistrationSubpageComponent },
    { path: "e-identification", component: EIdentificationSubpageComponent },
    { path: "e-learning", component: ELearningSubpageComponent },
    { path: "e-sora", component: ESoraSubpageComponent },
    { path: "mission-planning", component: MissionPlanningSubpageComponent },
    { path: "three-areas", component: ThreeAreasSubpageComponent },
    { path: "sah", component: SahSubpageComponent },
    { path: "roadmap", component: RoadmapSubpageComponent },
    { path: "pilot-operator-search", component: PilotOperatorSearchComponent },
    { path: "system-state", component: SystemStateComponent },
    { path: NOT_FOUND_ROUTE, component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MainPageLibRoutingModule {}
