<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.threeAreas.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.threeAreas.mainMessageOne" | transloco }}
        </p>
        <p class="message">
            {{ "dtmMainPageLib.subpage.threeAreas.mainMessageTwo" | transloco }}
        </p>
        <div class="main-image mobile">
            <img
                ngSrc="/assets/images/subpages/three-areas-laptop.webp"
                [alt]="'dtmMainPageLib.subpage.missionPlanning.bannerAltText' | transloco"
                height="851"
                width="1520"
            />
        </div>

        <div class="redirect-button-container desktop">
            <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
                "dtmMainPageLib.subpage.threeAreas.redirectLabel" | transloco
            }}</a>
        </div>
    </div>
</div>
<div class="redirect-button-container mobile">
    <a class="button-primary" [href]="UAV_PANSA_URL" target="_blank" rel="noopener noreferrer">{{
        "dtmMainPageLib.subpage.threeAreas.redirectLabel" | transloco
    }}</a>
</div>

<div class="main-image desktop">
    <img
        ngSrc="/assets/images/subpages/three-areas-laptop.webp"
        [alt]="'dtmMainPageLib.subpage.missionPlanning.bannerAltText' | transloco"
        dtmUiPageZoomImage
        height="851"
        width="1520"
    />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/exam.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.threeAreas.profits.complexityLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.profits.complexityMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/shield.svg" alt="" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.threeAreas.profits.supportLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.profits.supportMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/graph.svg" alt="" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.threeAreas.profits.permissionsLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.profits.permissionsMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container analysis-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/three-areas-analysis.webp"
                        [alt]="'dtmMainPageLib.subpage.threeAreas.fastPermissionsImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.threeAreas.fastPermissionsTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.fastPermissionsMessage" | transloco }}
                    </p>
                    <h3 class="title following-title">{{ "dtmMainPageLib.subpage.threeAreas.deconflictionTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.deconflictionMessageOne" | transloco }}
                    </p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.threeAreas.deconflictionMessageTwo" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationMessageOne" | transloco }}</p>
                <p class="message">{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationMessageTwo" | transloco }}</p>
                <ul class="message-list">
                    <li>{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationListItemOne" | transloco }}</li>
                    <li>{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationListItemTwo" | transloco }}</li>
                    <li>{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationListItemThree" | transloco }}</li>
                    <li>{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationListItemFour" | transloco }}</li>
                    <li>{{ "dtmMainPageLib.subpage.threeAreas.missionPreparationListItemFive" | transloco }}</li>
                </ul>
            </div>
            <div class="image-container complexity-image">
                <img class="background-blob" src="/assets/images/subpages/blob_2.svg" alt="" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/three-areas-complexity.webp"
                    [alt]="'dtmMainPageLib.subpage.threeAreas.missionPreparationImageAltText' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container monitoring-system-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/three-areas-monitoring-system.webp"
                        [alt]="'dtmMainPageLib.subpage.threeAreas.monitoringSystemImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.threeAreas.monitoringSystemTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.threeAreas.monitoringSystemMessageOne" | transloco }}</p>
                    <p class="message">{{ "dtmMainPageLib.subpage.threeAreas.monitoringSystemMessageOTwo" | transloco }}</p>
                    <ul class="message-list">
                        <li>{{ "dtmMainPageLib.subpage.threeAreas.monitoringSystemListItemOne" | transloco }}</li>
                        <li>{{ "dtmMainPageLib.subpage.threeAreas.monitoringSystemListItemTwo" | transloco }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
