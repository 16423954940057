import { isPlatformServer } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { MatLegacyMenuTrigger } from "@angular/material/legacy-menu";
import { FAQ_SECTION, MAIN_PAGE_SERVICES_LIST } from "@dtm-frontend/main-page-lib";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";

interface HeaderComponentState {
    isServicesMenuOpen: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-main-page-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    protected readonly languages = LANGUAGE_CONFIGURATION.languageDefinitions;
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    protected readonly isPlatformServer = isPlatformServer(this.platformId);
    protected readonly isMenuClosed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isServicesMenuOpen$ = this.localStore.selectByKey("isServicesMenuOpen");
    protected readonly MAIN_PAGE_SERVICES_LIST = MAIN_PAGE_SERVICES_LIST;
    protected readonly FAQ_SECTION = FAQ_SECTION;

    @ViewChild("servicesMenuTrigger") protected readonly servicesMenuTrigger!: MatLegacyMenuTrigger;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<HeaderComponentState>,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(PLATFORM_ID) private readonly platformId: string
    ) {
        localStore.setState({ isServicesMenuOpen: false });
        deviceSizeService
            .getSizeObservable(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.servicesMenuTrigger?.closeMenu());
    }

    protected setActiveLanguage(newLanguageCode: LanguageCode) {
        this.store.dispatch(new UIActions.SetActiveLanguage(newLanguageCode));
    }

    protected toggleSidebar() {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(false));
    }

    protected toggleServicesMenu(isOpen: boolean) {
        this.localStore.patchState({ isServicesMenuOpen: isOpen });
    }
}
