import { ChangeDetectionStrategy, Component } from "@angular/core";
import { REGISTER_PANSA_URL, UAV_PANSA_URL } from "../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-prompt",
    templateUrl: "./prompt.component.html",
    styleUrls: ["./prompt.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
    protected readonly REGISTER_PANSA_URL = REGISTER_PANSA_URL;
}
