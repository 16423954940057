import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from "@angular/core";
import { Router, Scroll } from "@angular/router";
import { FAQ_SECTION } from "@dtm-frontend/main-page-lib";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { APPROX_DAYS_IN_YEAR, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { combineLatest, delay, filter, fromEvent, map, pairwise, takeUntil } from "rxjs";

const DTM_MAIN_PAGE_COOKIE = "DTM_MAIN_PAGE_COOKIE";
const DTM_MAIN_PAGE_COOKIE_VALUE = "true";
const HIDE_SCROLL_BUTTON_DELAY = 5000;
const MIN_SCROLL_HEIGHT_FOR_BUTTON = 100;

interface AppComponentState {
    isCookiesInfoVisible: boolean;
    isScrollToTopButtonVisible: boolean;
    lastScrollTime: Date;
}

@UntilDestroy()
@Component({
    selector: "dtm-main-page-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AppComponent {
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
    protected readonly isCookiesInfoVisible$ = this.localStore.selectByKey("isCookiesInfoVisible");
    protected readonly isMenuClosed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isScrollToTopButtonVisible$ = this.localStore.selectByKey("isScrollToTopButtonVisible");
    protected readonly isDesktop$ = this.deviceService.getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide);

    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        @Inject(DOCUMENT) private document: Document,
        private readonly ssrCookieService: SsrCookieService,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<AppComponentState>,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly router: Router,
        private readonly deviceService: DeviceSizeService
    ) {
        router.events
            .pipe(
                filter((element): element is Scroll => element instanceof Scroll),
                untilDestroyed(this)
            )
            .subscribe((element) => {
                const anchor: string | null = element.anchor;
                if (anchor === FAQ_SECTION) {
                    document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth" });
                }
            });

        localStore.setState({
            isCookiesInfoVisible:
                this.ssrCookieService.get(DTM_MAIN_PAGE_COOKIE) !== DTM_MAIN_PAGE_COOKIE_VALUE && isPlatformBrowser(this.platformId),
            isScrollToTopButtonVisible: false,
            lastScrollTime: new Date(),
        });

        store.dispatch(new UIActions.SetMenuCollapsedState(true));

        combineLatest([
            this.isMenuClosed$,
            this.deviceSizeService.getSizeObservable(DeviceSize.Tablet, DeviceSize.Desktop, DeviceSize.DesktopWide),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([isMenuClosed, isDesktopSize]) => {
                if (!isMenuClosed && isDesktopSize) {
                    this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
                }
            });

        this.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
            if (isDesktop) {
                this.removeScrollToTopButton();
            } else {
                this.addScrollToTopButton();
            }
        });
    }

    protected hideCookiesInfo() {
        this.ssrCookieService.set(DTM_MAIN_PAGE_COOKIE, DTM_MAIN_PAGE_COOKIE_VALUE, { expires: APPROX_DAYS_IN_YEAR });
        this.localStore.patchState({ isCookiesInfoVisible: false });
    }
    protected showCookiesInfo() {
        this.localStore.patchState({ isCookiesInfoVisible: true });
    }

    protected closeMenu(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }

    protected setLanguage(language: LanguageCode) {
        this.store.dispatch(new UIActions.SetActiveLanguage(language));
    }

    protected scrollToTop() {
        this.document.scrollingElement?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    private removeScrollToTopButton() {
        this.localStore.patchState({ isScrollToTopButtonVisible: false });
    }

    private addScrollToTopButton() {
        fromEvent(this.document, "scroll")
            .pipe(
                map(() => this.document.scrollingElement?.scrollTop ?? 0),
                pairwise(),
                takeUntil(this.isDesktop$.pipe(RxjsUtils.filterFalsy())),
                map(([previousValue, currentValue]) => {
                    if (currentValue < MIN_SCROLL_HEIGHT_FOR_BUTTON) {
                        this.localStore.patchState({ isScrollToTopButtonVisible: false });

                        return;
                    }

                    if (previousValue > currentValue) {
                        this.localStore.patchState({
                            isScrollToTopButtonVisible: currentValue > MIN_SCROLL_HEIGHT_FOR_BUTTON,
                            lastScrollTime: new Date(),
                        });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();

        fromEvent(this.document, "scrollend")
            .pipe(
                delay(HIDE_SCROLL_BUTTON_DELAY),
                filter(() => this.localStore.selectSnapshotByKey("isScrollToTopButtonVisible")),
                filter(
                    () => new Date().getTime() - this.localStore.selectSnapshotByKey("lastScrollTime").getTime() > HIDE_SCROLL_BUTTON_DELAY
                ),
                takeUntil(this.isDesktop$.pipe(RxjsUtils.filterFalsy())),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.localStore.patchState({ isScrollToTopButtonVisible: false });
            });
    }
}
