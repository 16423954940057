<div class="prompt-container">
    <div class="info">
        <h2>{{ "dtmMainPageLib.prompt.header" | transloco }}</h2>
        <p class="text">{{ "dtmMainPageLib.prompt.text" | transloco }}</p>
        <div class="call-to-action-buttons">
            <a [href]="UAV_PANSA_URL" class="button button-primary desktop-button login-button" rel="noopener noreferrer" target="_blank">
                {{ "dtmMainPageLib.prompt.loginLinkLabel" | transloco }}
            </a>
            <a
                [href]="REGISTER_PANSA_URL"
                class="button button-secondary desktop-button register-button"
                rel="noopener noreferrer"
                target="_blank"
            >
                {{ "dtmMainPageLib.prompt.registerLinkLabel" | transloco }}
            </a>
        </div>
    </div>
    <div class="image-container">
        <img src="/assets/images/prompt-background.svg" class="prompt-background" alt="" />
        <img src="/assets/images/prompt.png" class="prompt-screenshot" alt="" dtmUiPageZoomImage />
    </div>
    <a class="button-primary mobile-button" [href]="UAV_PANSA_URL" rel="noopener noreferrer" target="_blank">
        {{ "dtmMainPageLib.prompt.loginLinkLabel" | transloco }}
    </a>
    <a class="button-secondary mobile-button register-button" [href]="REGISTER_PANSA_URL" rel="noopener noreferrer" target="_blank">
        {{ "dtmMainPageLib.prompt.registerLinkLabel" | transloco }}
    </a>
</div>
