import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainPageLibModule, PILOT_OPERATOR_SEARCH_API_TOKENS, SYSTEM_STATE_API_TOKENS } from "@dtm-frontend/main-page-lib";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { ACCESSIBILITY_STATEMENT_URL, LOCAL_STORAGE, Logger, LoggerModule, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE, TranslocoService } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { first, tap } from "rxjs";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { initialAppSetup } from "./utils/initial-app-setup";

Logger.initialize("https://083e8323264b1dbb82dee2ef814bd074@sentry.pansa.cloud/9");

@NgModule({
    declarations: [AppComponent, HeaderComponent],
    imports: [
        NgxsModule.forRoot([], {
            // NOTE: must be first because of https://github.com/ngxs/store/issues/375
            developmentMode: !environment.production,
        }),
        AppRoutingModule,
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        BrowserAnimationsModule,
        HttpClientModule,
        PushModule,
        MatButtonModule,
        NgxsStoragePluginModule.forRoot({
            key: ["ui"],
            storage: StorageOption.LocalStorage,
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
            name: "MainPage",
        }),
        LoggerModule.forRoot(environment.name, !environment.production),
        I18nRootModule.forRoot({
            developmentMode: !environment.production,
            ...LANGUAGE_CONFIGURATION,
        }),
        SharedUiModule,
        VersionModule.forRoot(),
        MainPageLibModule,
        MatMenuModule,
        LetModule,
    ],
    providers: [
        {
            provide: VERSION_DATA_ENDPOINTS,
            useValue: environment.versionDataEndpoints,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initialAppSetup,
            deps: [LOCAL_STORAGE, Store, TranslocoService],
            multi: true,
        },
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "mainPage",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
            },
        },
        { provide: LOCAL_STORAGE, useValue: localStorage },
        { provide: TERMS_OF_USE_URL, useValue: environment.termsOfUseUrl },
        {
            provide: ACCESSIBILITY_STATEMENT_URL,
            useValue: environment.accessibilityStatementUrl,
        },
        {
            provide: SYSTEM_STATE_API_TOKENS,
            useValue: environment.systemStateEndpoints,
        },
        {
            provide: PILOT_OPERATOR_SEARCH_API_TOKENS,
            useValue: environment.pilotOperatorSearchEndpoints,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(translocoHelper: TranslationHelperService, titleService: Title) {
        translocoHelper
            .waitForTranslation("mainPage.appTitle")
            .pipe(first(), tap(titleService.setTitle.bind(titleService)))
            .subscribe();
    }
}
