import { ServiceStatus, SystemState, SystemStatus } from "./system-state-api.model";

export interface SystemStateResponseBody {
    status: SystemStatus;
    servicesHealth: ServicesHealthResponseBody[];
}

export interface ServicesHealthResponseBody {
    nativeName: string;
    name: string;
    status: ServiceStatus;
}

export function transformSystemStateResponseBodyToSystemState(response: SystemStateResponseBody): SystemState {
    return {
        status: response.status,
        servicesHealth: response.servicesHealth.map((serviceHealth) => ({
            nativeName: serviceHealth.nativeName,
            name: serviceHealth.name,
            status: serviceHealth.status,
        })),
    };
}
