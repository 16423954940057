import { BasicQualification, OperatorStatus } from "@dtm-frontend/shared/ui";

export interface PilotOperatorPublicProfile {
    status: OperatorStatus;
    number: string;
    qualifications: BasicQualification[];
    certificates?: PilotCertificates[];
    otherInformation: PilotOrOperatorOtherInformation[];
    isLegacyProfile: boolean;
}

export interface PilotOrOperatorOtherInformation {
    id: string;
    text: string;
}

export interface PilotCertificates {
    name: string;
    validUntil: Date;
}

export interface PilotOrOperatorSearchResult {
    operator?: PilotOperatorPublicProfile;
    pilot?: PilotOperatorPublicProfile;
}

export interface PilotOrOperatorSearchResultError {
    type: PilotOrOperatorSearchResultErrorType;
}

export enum PilotOrOperatorSearchResultErrorType {
    NotFound = "NotFound",
    Unknown = "Unknown",
}
