import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainPageLibRoutingModule } from "@dtm-frontend/main-page-lib";
import { NOT_FOUND_ROUTE } from "@dtm-frontend/shared/utils";

const routes: Routes = [{ path: "**", redirectTo: `/${NOT_FOUND_ROUTE}` }];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }), MainPageLibRoutingModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
