import { ChangeDetectionStrategy, Component } from "@angular/core";
import { E_LEARNING_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-e-learning-subpage",
    templateUrl: "./e-learning-subpage.component.html",
    styleUrls: ["./e-learning-subpage.component.scss", "../shared.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ELearningSubpageComponent {
    protected readonly E_LEARNING_URL = E_LEARNING_URL;
}
