import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UAV_PANSA_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-three-areas-subpage",
    templateUrl: "./three-areas-subpage.component.html",
    styleUrls: ["three-areas-subpage.component.scss", "../shared.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeAreasSubpageComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
}
