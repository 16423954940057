<ng-container *ngrxLet="isMenuClosed$ as isMenuClosed">
    <div class="sidebar-overlay" [class.is-collapsed]="isMenuClosed" (click)="closeMenu(true)"></div>

    <div class="container" *ngrxLet="activeLanguage$ as activeLanguage">
        <div class="header" [attr.inert]="!isMenuClosed || null">
            <dtm-main-page-header></dtm-main-page-header>
        </div>
        <dtm-main-page-lib-menu
            *ngIf="!isMenuClosed"
            (menuCollapse)="closeMenu(true)"
            [activeLanguage]="activeLanguage"
            (activeLanguageSet)="setLanguage($event)"
        ></dtm-main-page-lib-menu>
        <div dtmUiDisplayVersion class="content" [attr.inert]="!isMenuClosed || null">
            <router-outlet></router-outlet>
            <dtm-ui-cookies-popup *ngIf="isCookiesInfoVisible$ | ngrxPush" (cookiesHide)="hideCookiesInfo()"></dtm-ui-cookies-popup>
            <button
                type="button"
                [class.show]="isScrollToTopButtonVisible$ | ngrxPush"
                class="button-icon scroll-top"
                (click)="scrollToTop()"
                [attr.aria-label]="'mainPage.pageScrollTopAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-up"></dtm-ui-icon>
            </button>
            <dtm-ui-webpage-footer [activeLanguage]="activeLanguage" (cookiesInfoSelect)="showCookiesInfo()"></dtm-ui-webpage-footer>
        </div>
    </div>
</ng-container>
