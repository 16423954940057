<div class="main-container">
    <div class="img"></div>
    <div>
        <h1 class="title">404</h1>
        <h2 class="header">{{ "dtmMainPageLib.pageNotFound.header" | transloco }}</h2>
        <p class="message">{{ "dtmMainPageLib.pageNotFound.message" | transloco }}</p>
        <button class="button-primary" type="button" routerLink="/">
            {{ "dtmMainPageLib.pageNotFound.backToDashboardLabel" | transloco }}
        </button>
    </div>
</div>
