export interface SystemState {
    status: SystemStatus;
    servicesHealth: ServicesHealth[];
}

export interface ServicesHealth {
    nativeName: string;
    name: string;
    status: ServiceStatus;
}

export enum SystemStatus {
    UP = "UP",
    SERVICES_ISSUES = "SERVICES_ISSUES",
}

export enum ServiceStatus {
    UP = "UP",
    SERVICE_ISSUE = "SERVICE_ISSUE",
    DOWN = "DOWN",
}
