import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UAV_PANSA_URL } from "../../../utils/defaults";

@Component({
    selector: "dtm-main-page-lib-e-sora",
    templateUrl: "./e-sora-subpage.component.html",
    styleUrls: ["../shared.scss", "./e-sora-subpage.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ESoraSubpageComponent {
    protected readonly UAV_PANSA_URL = UAV_PANSA_URL;
}
