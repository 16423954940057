<div class="grid">
    <div class="main-section">
        <h1>
            {{ "dtmMainPageLib.subpage.caa.header" | transloco }}
        </h1>
        <p class="message">
            {{ "dtmMainPageLib.subpage.caa.mainMessage" | transloco }}
        </p>
        <div class="main-image mobile">
            <img src="/assets/images/subpages/caa-laptop.webp" [alt]="'dtmMainPageLib.subpage.caa.bannerAltText' | transloco" />
        </div>
    </div>
</div>

<div class="main-image desktop">
    <img src="/assets/images/subpages/caa-laptop.webp" [alt]="'dtmMainPageLib.subpage.caa.bannerAltText' | transloco" dtmUiPageZoomImage />
</div>

<div class="sections-container">
    <div class="grid profits">
        <h2>
            {{ "dtmMainPageLib.subpage.profitsHeader" | transloco }}
        </h2>
        <div class="profits-list">
            <div class="profit">
                <img src="/assets/images/laptop.svg" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.caa.profits.complexityLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.caa.profits.complexityMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/laptop-user.svg" />
                <div class="profits-info">
                    <p class="title">{{ "dtmMainPageLib.subpage.caa.profits.speedLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.caa.profits.speedMessage" | transloco }}
                    </p>
                </div>
            </div>
            <div class="profit">
                <img src="/assets/images/open-mail.svg" />
                <div>
                    <p class="title">{{ "dtmMainPageLib.subpage.caa.profits.communicationLabel" | transloco }}</p>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.caa.profits.communicationMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <h2>
                {{ "dtmMainPageLib.subpage.possibilitiesHeader" | transloco }}
            </h2>
            <div class="section">
                <div class="image-container operators-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_3.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/caa-operators.webp"
                        [alt]="'dtmMainPageLib.subpage.caa.pilotsAndOperatorsImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.caa.pilotsAndOperatorsTitle" | transloco }}</h3>
                    <p class="message">
                        {{ "dtmMainPageLib.subpage.caa.pilotsAndOperatorsMessage" | transloco }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="section">
            <div class="section-info">
                <h3 class="title">{{ "dtmMainPageLib.subpage.caa.trainingsAndExamsTitle" | transloco }}</h3>
                <p class="message">{{ "dtmMainPageLib.subpage.caa.trainingsAndExamsMessage" | transloco }}</p>
            </div>
            <div class="image-container exam-results-image">
                <img class="background-blob" src="/assets/images/subpages/blob_2.svg" alt="" />
                <img
                    class="section-image image-with-box-shadow"
                    src="/assets/images/subpages/caa-exam-results.webp"
                    [alt]="'dtmMainPageLib.subpage.caa.trainingsAndExamsMessage' | transloco"
                    dtmUiPageZoomImage
                />
            </div>
        </div>
    </div>
    <div class="dark-background-section">
        <div class="grid">
            <div class="section">
                <div class="image-container permits-image">
                    <img class="background-blob" src="/assets/images/subpages/blob_4.svg" alt="" />
                    <img
                        class="section-image image-with-box-shadow"
                        src="/assets/images/subpages/caa-permits.webp"
                        [alt]="'dtmMainPageLib.subpage.caa.permitsImageAltText' | transloco"
                        dtmUiPageZoomImage
                    />
                </div>
                <div class="section-info">
                    <h3 class="title">{{ "dtmMainPageLib.subpage.caa.permitsTitle" | transloco }}</h3>
                    <p class="message">{{ "dtmMainPageLib.subpage.caa.permitsMessagePartOne" | transloco }}</p>
                    <p class="message">{{ "dtmMainPageLib.subpage.caa.permitsMessagePartTwo" | transloco }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
