<div class="wrapper" *ngrxLet="systemStatus$ as systemStatus">
    <div class="content">
        <ng-container *ngIf="systemStatus; else servicesDownTemplate">
            <ng-container [ngSwitch]="systemStatus.status">
                <div class="status" *ngSwitchCase="SystemStatus.UP">
                    <h1 class="up">
                        <dtm-ui-icon class="status-icon" name="checkbox-circle-fill"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.systemState.upLabel" | transloco }}
                    </h1>
                </div>
                <div class="status" *ngSwitchCase="SystemStatus.SERVICES_ISSUES">
                    <h1 class="issues">
                        <dtm-ui-icon class="status-icon" name="alert"> </dtm-ui-icon>
                        {{ "dtmMainPageLib.systemState.warningLabel" | transloco }}
                    </h1>
                    {{ "dtmMainPageLib.systemState.servicesDownMessage" | transloco }}
                </div>
            </ng-container>
            <div class="system" *ngFor="let item of systemStatus.servicesHealth">
                <h2>{{ (activeLanguage$ | ngrxPush) === "pl" ? item.nativeName : item.name }}</h2>
                <div
                    class="status-chips"
                    [ngClass]="{
                        up: item.status === ServiceStatus.UP,
                        issues: item.status === ServiceStatus.SERVICE_ISSUE,
                        error: item.status === ServiceStatus.DOWN
                    }"
                >
                    <dtm-ui-icon
                        [name]="
                            item.status === ServiceStatus.UP
                                ? 'checkbox-circle'
                                : item.status === ServiceStatus.SERVICE_ISSUE
                                ? 'alert'
                                : 'error-warning'
                        "
                    >
                    </dtm-ui-icon>
                    {{ "dtmMainPageLib.systemState.status" | transloco : { status: item.status } }}
                </div>
            </div>
        </ng-container>

        <ng-template #servicesDownTemplate>
            <div class="status">
                <h1 class="error">
                    <dtm-ui-icon class="status-icon" name="error-warning"></dtm-ui-icon>
                    {{ "dtmMainPageLib.systemState.errorLabel" | transloco }}
                </h1>
                {{ "dtmMainPageLib.systemState.servicesDownMessage" | transloco }}
            </div>
        </ng-template>
    </div>
</div>
