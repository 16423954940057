import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { ServiceStatus, SystemStatus } from "../../services/system-state-api/system-state-api.model";
import { SystemStateApiService } from "../../services/system-state-api/system-state-api.service";

@Component({
    templateUrl: "./system-state.component.html",
    styleUrls: ["./system-state.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStateComponent {
    private readonly store = inject(Store);

    protected readonly SystemStatus = SystemStatus;
    protected readonly ServiceStatus = ServiceStatus;
    protected readonly systemStatus$ = inject(SystemStateApiService).getSystemState();
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage);
}
